<template>
  <n-modal v-bind="$props">
    <!-- prettier-ignore -->
    <Form ref="modalForm" class="n-modal-container" :label-width="labelWidth" :model="model" @submit.native.prevent>
      <n-row-col2>
        <n-field-id v-model="model.id" label="id" disabled />
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="!isCreate || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.customerId" field-name="customerId" label="customerId" :error="error.customerId" :disabled="isEdit || disabled" />
        <n-field-selection v-model="model.groupId" field-name="groupId" selection-name="company.group" label="group" :error="error.groupId" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.lpType" :error="error.type" enum-name="LandingPageType" label="type" :disabled="isEdit || disabled" />
        <n-field-enum v-model="model.status" :error="error.status" enum-name="CustomerOfferStatus" label="status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.iosStoreId" field-name="iosStoreId" label="iosStoreId" :error="error.iosStoreId" :disabled="isEdit || disabled" />
        <n-field-text v-model="model.andStoreId" field-name="andStoreId" label="andStoreId" :error="error.andStoreId" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.priority" :error="error.priority" enum-name="CustomerOfferPriority" label="priority" :disabled="disabled" />
        <!-- variantPrefer -->
        <n-field-enum v-model="model.variantPrefer" field-name="variantPrefer" enum-name="LandingPageVariant" :error="error.variantPrefer" label="variantPrefer" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- offerDate -->
        <n-field-date v-model="model.offerDate" label="offerDate" disabled />
        <!-- expireDate -->
        <n-field-date v-model="model.expireDate" label="expireDate" disabled />
      </n-row-col2>
      <n-row-col2>
        <!-- offerTime -->
        <n-field-datetime v-model="model.offerTime" label="offerTime" disabled />
        <!-- expireTime -->
        <n-field-datetime v-model="model.expireTime" label="expireTime" disabled />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, update, add } from '@/api/customer/customer-customer-offer';

export default NModal.extend({
  name: 'ModalCustomerOffer',
  components: { NModal },

  methods: {
    doValidate() {
      return validate(this.model);
    },

    doSubmit() {
      return this.isCreate ? add(this.model) : update(this.model);
    },
  },
});
</script>
