import {$fetch, $post, $put} from '@/services/axios';

export function fetch(params) {
  return $fetch(`customer/offers`, params);
}

export const CUSTOMER_OFFER_CSV_URL = `customer/offers/csv`;

export function validate(model) {
  return $post(`customer/offer/validate`, model);
}

export function add(model) {
  return $put(`customer/offer`, model);
}

export function update(model) {
  return $post(`customer/offer/${model.id}`, model);
}
